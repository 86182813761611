<template>
	<div class="page">
		<el-form class="form" label-width="90px">

			<div class="big_tit" style="margin:0">
				<div>APP用户部分</div>
				<div style="margin-left:10px">{{app_user.real_status_text}}</div>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="用户手机" style="width:340px">
					<el-input class="el_inner_width" v-model="app_user.tel" :disabled="app_user.has_ser" clearable>
						<el-button type="success" slot="append" @click="user_ser">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="用户姓名" style="width:300px">
					<el-input class="el_inner_width" v-model="app_user.name" :disabled="app_user.real_status==2" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="身份证号" style="width:320px">
					<el-input class="el_inner_width" v-model="app_user.id_card_num"  clearable>
						<el-button type="success" slot="append" @click="app_user_real_sub">实名</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="登录密码" style="width:300px">
					<el-input class="el_inner_width" v-model="app_user.login_pwd" type="password" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<div class="el_form_item" label="" style="width:32%">
					<div class="img_btn" @click="choose_img('app_user','headimg')">
						<div v-if="!app_user.headimg.src" class="btn">未上传</div>
						<img v-else :src="app_user.headimg.src" class="img">
					</div>
					<div class="bottom_text">头像</div>
				</div>
				<div class="el_form_item" style="width:32%;margin-left:1%">
					<div class="img_btn" @click="choose_img('app_user','id_card_img1')">
						<div v-if="!app_user.id_card_img1.src" class="btn">未上传</div>
						<img  v-else :src="app_user.id_card_img1.src" class="img">
					</div>
					<div class="bottom_text">身份证正面</div>
				</div>
				<div class="el_form_item" style="width:32%;margin-left:1%">
					<div class="img_btn" @click="choose_img('app_user','id_card_img2')">
						<div v-if="!app_user.id_card_img2.src" class="btn">未上传</div>
						<img v-else :src="app_user.id_card_img2.src" class="img">
					</div>
					<div class="bottom_text">身份证反面</div>
				</div>
			</div>


			<div class="big_tit">
				<div>公司信息</div>
				<div style="margin-left:10px">{{company_info.check_status_text}}</div>
			</div>
			<div class="tab1_inner">
				<div class="el_form_item" label="" style="width:49.5%">
					<div class="img_btn" @click="choose_img('company_info','license_img')">
						<div v-if="!company_info.license_img.src" class="btn">未上传</div>
						<img v-else :src="company_info.license_img.src" class="img">
					</div>
					<div class="bottom_text">营业执照照片</div>
				</div>
				<div class="el_form_item" label="" style="width:49.5%;margin-left:1%">
					<el-form-item class="el_form_item company_item" label="公司名称">
						<el-input class="el_inner_width" v-model="company_info.name" :disabled="company_info.check_status==2"></el-input>
					</el-form-item>
					<el-form-item class="el_form_item company_item" label="公司法人">
						<el-input class="el_inner_width" v-model="company_info.legal_person" :disabled="company_info.check_status==2"></el-input>
					</el-form-item>
					<el-form-item class="el_form_item company_item" label="信用代码">
						<el-input class="el_inner_width" v-model="company_info.license_code" :disabled="company_info.check_status==2"></el-input>
					</el-form-item>
					<el-form-item class="el_form_item company_item" label="公司地址">
						<el-input class="el_inner_width" v-model="company_info.addr" :disabled="company_info.check_status==2"></el-input>
					</el-form-item>
					<el-form-item class="el_form_item company_item" label="开户行">
						<el-input class="el_inner_width" v-model="company_info.bank_name" :disabled="company_info.check_status==2"></el-input>
					</el-form-item>
					<el-form-item class="el_form_item company_item" label="开户行卡号">
						<el-input class="el_inner_width" v-model="company_info.bank_card" :disabled="company_info.check_status==2"></el-input>
					</el-form-item>
					<el-form-item class="el_form_item company_item" label="公司电话">
						<el-input class="el_inner_width" v-model="company_info.tel" :disabled="company_info.check_status==2"></el-input>
					</el-form-item>
					<el-form-item class="company_item">
						<el-button type="success" size="mini" @click="company_sub">提交</el-button>
					</el-form-item>
				</div>
			</div>
		</el-form>
	</div>
</template>
<script>
	import {mapState} from 'vuex'
	export default {
		data() {
			return {

				//app用户部分
				app_user:{
					has_ser:false,
					tel:'',
					user_num:'',
					real_status:'',//1:未实名,2:审核通过,3:审核失败
					real_status_text:'未搜索',
					name:'',
					id_card_num:'',
					addr:'',
					login_pwd:'',//登录密码
					headimg:{//头像
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'headimg'
					},
					id_card_img1:{//身份证正面
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'idcard1'
					},
					id_card_img2:{//身份证反面
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'idcard2'
					}
				},

				//货主
				company_info:{
					check_status:'0',//状态(0:未添加,1:审核中,2:审核通过,3:审核未通过)
					check_status_text:'未添加',
					license_code:'',//统一社会信用代码
					name:'',//公司名称
					bank_name:'',//公司开户行
					bank_card:'',//公司开户行卡号
					legal_person:'',//公司法人
					addr:'',//公司地址
					tel:'',//公司电话
					license_img:{//营业执照照片
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'license'
					},
				},
				
				//提交锁
				sub_swh:false
			}
		},
		created() {

			this.init();
		},
		methods: {

			//保存公司信息
			company_sub(){

				if(!this.app_user.has_ser){//未搜索不能修改
					return;
				}

				if(this.company_info.check_status==2){//用户相关
					this.$my.other.msg({
						type:'warning',
						str:'公司审核已通过,不能修改'
					});
					return;
				}

				if(!this.$my.check.is_bl(this.company_info.license_code) && this.company_info.license_code){//统一信用代码
					this.$my.other.msg({
						type:'warning',
						str:'统一信用代码格式有误'
					});
					return;
				}

				if(!this.$my.check.is_tel(this.company_info.tel) && this.company_info.tel){//公司电话
					this.$my.other.msg({
						type:'warning',
						str:'公司电话格式有误'
					});
					return;
				}

				//保存ocr结果
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'company_edit_admin',
						user_num:this.app_user.user_num,
						name:this.company_info.name,
						legal_person:this.company_info.legal_person,
						license_code:this.company_info.license_code,
						addr:this.company_info.addr,
						bank_name:this.company_info.bank_name,
						bank_card:this.company_info.bank_card,
						tel:this.company_info.tel,
						login_pwd:this.app_user.login_pwd
					},
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'保存成功'
						});
						return;
					}
				});
			},

			//提交实名信息
			app_user_real_sub(){

				if(!this.app_user.has_ser){//未搜索不能修改
					return;
				}

				if(this.app_user.real_status==2){//用户相关
					this.$my.other.msg({
						type:'warning',
						str:'用户已实名,不能修改'
					});
					return;
				}

				//提交实名信息
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_edit_by_admin',
						is_real_check:1,//要求进行实名认证(需要同时传 姓名和身份证号)
						user_num:this[type].user_num,
						name:data.name,
						id_card_num:data.id_card_num,
						addr:data.addr,
					},
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'实名成功'
						});
						return;
					}
				});
			},

			//搜索
			user_ser(){

				if(this.app_user.has_ser){
					return;
				}

				if(!this.$my.check.is_tel(this.app_user.tel)){
					this.$my.other.msg({
						type:'warning',
						str:'手机号码格式有误'
					});
					return;
				}

				//查找用户
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_list_by_admin',
						tel:this.app_user.tel
					},
					callback:(data)=>{

						//未注册
						if(data.list.length==0){
							
							//注册之
							this.$my.net.req({
								data:{
									mod:'app_user',
									ctr:'app_user_add_by_admin',
									tel:this.app_user.tel
								},
								callback:(data)=>{
									
									//已查找
									this.app_user.has_ser=true;

									//缓存用户数据
									this.app_user.user_num=data.user_num
									this.app_user.real_status=1
									this.app_user.real_status_text='未实名'
									this.app_user.name='未实名'

									//货主信息查找
									this.company_ser();
								}
							});
							return;
						}

						//已查找
						this.app_user.has_ser=true;

						//取出用户信息
						let user_info=data.list[0]

						//缓存用户数据
						this.app_user.user_num=user_info.user_num
						this.app_user.real_status=user_info.real_status
						switch(user_info.real_status){
							case '1':this.app_user.real_status_text='未实名';break;
							case '2':this.app_user.real_status_text='已实名';break;
							case '3':this.app_user.real_status_text='实名失败';break;
						}
						this.app_user.name=user_info.name
						this.app_user.id_card_num=user_info.id_card_num

						if(user_info.headimg){//头像
							this.app_user.headimg.key=user_info.headimg
							this.app_user.headimg.src=this.$my.qiniu.make_src(this.app_user.headimg.bucket,user_info.headimg)
						}

						if(user_info.id_card_img1){//身份证正面
							this.app_user.id_card_img1.key=user_info.id_card_img1
							this.app_user.id_card_img1.src=this.$my.qiniu.make_src(this.app_user.id_card_img1.bucket,user_info.id_card_img1)
						}

						if(user_info.id_card_img2){//身份证反面
							this.app_user.id_card_img2.key=user_info.id_card_img2
							this.app_user.id_card_img2.src=this.$my.qiniu.make_src(this.app_user.id_card_img2.bucket,user_info.id_card_img2)
						}

						//货主信息查找
						this.company_ser();
					}
				});
			},

			//货主信息查找
			company_ser(){

				if(!this.app_user.user_num){
					this.$my.other.msg({
						type:'warning',
						str:'公司信息查找:缺少用户编号'
					});
					return;
				}

				//查找
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'company_list_admin',
						user_num:this.app_user.user_num
					},
					callback:(data)=>{

						//未注册
						if(data.list.length==0){
							
							//添加公司数据
							this.$my.net.req({
								data:{
									mod:'app_user',
									ctr:'company_add_admin',
									user_num:this.app_user.user_num
								},
								callback:(data)=>{
									
									//缓存司机数据
									this.company_info.check_status='1'
									this.company_info.check_status_text='审核中'
								}
							});

							//添加公司扩展数据
							this.$my.net.req({
								data:{
									mod:'app_user',
									ctr:'shipper_extend_add_admin',
									user_num:this.app_user.user_num
								},
								take_over_control:1,
								callback:(data)=>{
									// console.log(data)
								}
							});
							return;
						}

						//取出货主信息
						let company_info=data.list[0]

						//缓存货主数据
						this.company_info.check_status=company_info.check_status
						switch(company_info.check_status){
							case '1':this.company_info.check_status_text='审核中';break;
							case '2':this.company_info.check_status_text='审核通过';break;
							case '3':this.company_info.check_status_text='审核未通过';break;
						}
						this.company_info.license_code=company_info.license_code
						this.company_info.name=company_info.name
						this.company_info.bank_name=company_info.bank_name
						this.company_info.bank_card=company_info.bank_card
						this.company_info.legal_person=company_info.legal_person
						this.company_info.addr=company_info.addr
						this.company_info.tel=company_info.tel
						
						if(company_info.license_img){//营业执照照片
							this.company_info.license_img.key=company_info.license_img
							this.company_info.license_img.src=this.$my.qiniu.make_src(this.company_info.license_img.bucket,company_info.license_img)
						}
					}
				});
			},

			//选择图片
			choose_img(type,img_name) {

				if(!this.app_user.has_ser){//未搜索不能修改
					return;
				}

				if(type=='app_user' && this.app_user.real_status==2){//用户相关
					this.$my.other.msg({
						type:'warning',
						str:'用户已实名,不能修改'
					});
					return;
				}

				//货主相关
				if(type=='company_info'){
					
					if(this.company_info.check_status=='0'){
						this.$my.other.msg({
							type:'warning',
							str:'货主数据初始化未完成,请稍等,或者刷新重试'
						});
						return;
					}

					if(this.company_info.check_status=='2'){
						this.$my.other.msg({
							type:'warning',
							str:'货主数据已审核通过,不能修改'
						});
						return;
					}
				}

				//取出相应对象
				let ctr_obj=this[type][img_name]

				//选择图片
				this.$my.qiniu.choose_img({
					success:(res)=>{
						ctr_obj.src=res.src;
						ctr_obj.is_choose=true;
						ctr_obj.is_upl=false;

						//上传图片
						this.$my.qiniu.upl({
							bucket:ctr_obj.bucket,
							key:ctr_obj.key,
							file_path:ctr_obj.src,
							callback:(data)=>{
								ctr_obj.is_upl=true;
								ctr_obj.key=data.key;
								this.save_img(type,img_name);
							}
						});
					}
				});
			},
			save_img(type,img_name){

				/* 保存图片数据 */
				if(type=='company_info'){//货主部分
					
					//构造表单
					let form={
						mod:'app_user',
						ctr:'company_edit_admin',
						user_num:this.app_user.user_num,
					};
					form[img_name]=this[type][img_name].key

					//发送
					this.$my.net.req({
						data:form,
						callback:(data)=>{

							this.$my.other.msg({
								type:'success',
								str:'货主证件保存成功'
							});

							//识别营业执照照片
							if(img_name=='license_img'){

								this.$my.other.msg({
									type:'success',
									str:'正在识别营业执照照片信息,请稍后'
								});

								this.$my.net.req({
									data:{
										mod:'res',
										ctr:'img_ocr',
										img_type:'business_license',
										img_url:this.$my.qiniu.make_src(this[type][img_name].bucket,this[type][img_name].key)
									},
									callback:(data)=>{
										this[type].name=data.company_name
										this[type].legal_person=data.legal_person
										this[type].license_code=data.reg_num
										this[type].addr=data.address

										this.$my.other.msg({
											type:'success',
											str:'识别营业执照照片成功,正在保存营业执照照片识别结果'
										});

										//保存ocr结果
										this.$my.net.req({
											data:{
												mod:'app_user',
												ctr:'company_edit_admin',
												user_num:this.app_user.user_num,
												name:data.company_name,
												legal_person:data.legal_person,
												license_code:data.reg_num,
												addr:data.address,
											},
											callback:(data)=>{
												this.$my.other.msg({
													type:'success',
													str:'营业执照照片识别结果保存成功'
												});
												return;
											}
										});
									}
								});
							}
						}
					});

				}else if(type=='app_user'){//用户部分

					//构造表单
					let form={
						mod:'app_user',
						ctr:'app_user_edit_by_admin',
						user_num:this.app_user.user_num,
					};
					form[img_name]=this[type][img_name].key

					//发送
					this.$my.net.req({
						data:form,
						callback:(data)=>{

							this.$my.other.msg({
								type:'success',
								str:'APP用户图片保存成功'
							});

							//识别身份证
							if(img_name=='id_card_img1'){

								this.$my.other.msg({
									type:'success',
									str:'正在识别身份证信息,请稍后'
								});

								this.$my.net.req({
									data:{
										mod:'res',
										ctr:'img_ocr',
										img_type:'id_card',
										img_url:this.$my.qiniu.make_src(this[type][img_name].bucket,this[type][img_name].key)
									},
									callback:(data)=>{
										this[type].name=data.name
										this[type].id_card_num=data.id_card_num
										this[type].addr=data.address

										//询问是否保存姓名和身份证哈
										this.$my.other.confirm({
											content:`身份证信息识别成功,点击'确定'进行实名认证`,
											confirm:()=>{

												//提交实名信息
												this.$my.net.req({
													data:{
														mod:'app_user',
														ctr:'app_user_edit_by_admin',
														is_real_check:1,//要求进行实名认证(需要同时传 姓名和身份证号)
														user_num:this[type].user_num,
														name:data.name,
														id_card_num:data.id_card_num,
														addr:data.addr,
													},
													callback:(data)=>{
														this.$my.other.msg({
															type:'success',
															str:'实名成功'
														});
														return;
													}
												});
											}
										});
									}
								});
							}
						}
					});
				}
			},

			//初始化
			init(){

				//app用户部分
				this.app_user={
					has_ser:false,
					tel:'',
					user_num:'',
					real_status:'',//1:未实名,2:审核通过,3:审核失败
					real_status_text:'未搜索',
					name:'',
					id_card_num:'',
					addr:'',
					headimg:{//头像
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'headimg'
					},
					id_card_img1:{//身份证正面
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'idcard1'
					},
					id_card_img2:{//身份证反面
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'idcard2'
					}
				},

				//货主
				this.company_info={
					check_status:'0',//状态(0:未添加,1:审核中,2:审核通过,3:审核未通过)
					check_status_text:'未添加',
					license_code:'',//统一社会信用代码
					name:'',//公司名称
					bank_name:'',//公司开户行
					bank_card:'',//公司开户行卡号
					legal_person:'',//公司法人
					addr:'',//公司地址
					tel:'',//公司电话
					license_img:{//营业执照照片
						src:'',
						key:'',
						is_upl:false,
						is_choose:false,
						bucket:'license'
					},
				}
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
		.img_btn{
			border: 1px solid #bbb;
			border-radius: 5px;
			cursor: pointer;
			padding: 5px;
			height:300px;
			.btn{
				text-align: center;
				line-height: 290px;
				font-size: 14px;
			}

			.img{
				display: block;
				margin:auto;
				max-width: 100%;
				max-height: 290px
			}
		}
		.bottom_text{
			margin-top: 5px;
			text-align: center;
		}
	}
	.company_item{
		margin-top:4px;
		width:100%
	}
	.big_tit{
		display: flex;
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.bottom_btns{
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: 100%;
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form{
		height: calc(100% - 60px);
		margin-top:20px;
		padding:0 15px;
		overflow-y: auto;
	}

	.page{
		position:relative;
		background-color: #fff;
		border-radius: 10px;
		height: 100%;
		width: 1040px;
		overflow: auto;
		margin: auto;
	}

</style>